//
//
//
//

import { Loader } from '@googlemaps/js-api-loader';

export default {
  data: () => ({}),
  created() {
    var mapOptions = {
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: 4,
    };

    const loader = new Loader({
      apiKey: "AIzaSyAuySimVUH92t4IXe2ug8VyhF4l6u4opog",
      version: "weekly",
      libraries: ["places"],
    });

    // Promise
    loader
      .load()
      .then((google) => {
        new google.maps.Map(document.getElementById("map"), mapOptions);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {},
};
